define('gtmEvents', ['app', 'siteObj', '$console'], function(app, siteObj, $console) {

  var _tracking = {

    init: function() {
      // Check for the events and data layer array for GTM
      window.dataLayer = window.dataLayer || [];

      app.subscribe('tracking/record', function(category, action, label, labelValue, slot = '') {
        _tracking._record(category, action, label, labelValue, slot);
      });

      app.subscribe('ga4tracking/record', function(event, eventName, currencyCode, data) {
        let dataLayerObject = {
          'event': event,
          'event_name': eventName,
          'ecommerce': {
            'currencyCode': currencyCode,
            'items' : data
          }
        }
        window.dataLayer.push({ecommerce : null});
        window.dataLayer.push(dataLayerObject);
      });

      app.subscribe('ga4tracking/record/coupon', function(event, eventName, code) {
        let dataLayerObject = {
          'event': event,
          'event_name': eventName,
          'coupon_code': code
        }
        window.dataLayer.push(dataLayerObject);
      });

      app.subscribe('ga4tracking/record/filter', function(event, eventName, filter) {
        let dataLayerObject = {
          'event': event,
          'event_name': eventName,
          'filter': filter
        }
        window.dataLayer.push(dataLayerObject);
      });

      app.subscribe('record/general', function(dataLayerValues) {
        window.dataLayer.push({ecommerce: null});
        window.dataLayer.push(dataLayerValues);
      });

      app.subscribe('tracking/recordEach', function(nodeList, callback) {
        _tracking._record(nodeList, callback);
      });

      app.subscribe('tracking/widget', function(element) {
        _tracking._widgetTrack(element);
      });

      app.subscribe('tracking/pyop', function(element) {
        _tracking._pyopTrack(element);
      });

      app.subscribe('tracking/ecommerce', function(category, action, label, labelValue, currencyCode) {
        _tracking._ecommerce(category, action, label, labelValue, currencyCode);
      });

      app.subscribe('tracking/fieldtiming', function(timingCategory, timingVariable, timingLabel, timingValue) {
        _tracking._fieldtiming(timingCategory, timingVariable, timingLabel, timingValue);

      });
    },

    /**
     * Push event to Google Tag Manager
     * @param {object} object - Object sent to Google Tag Manager
     * @returns {boolean}
     */
    _gtmEventTrack: function(object) {

      this._log('%cGTM Event', 'background: #222; color: #2196f3', object);
      if (typeof object === 'object') {
        object.ecommerce && window.dataLayer.push({ecommerce : null});
        window.dataLayer.push(object);
        return true;
      }
      return false;
    },

    _log: function(event='event',style='',object={}) {
      siteObj.debugFlag  && $console.debug(event, style, object);
    },

    /**
     * Basic tracking for example error pages (404, my-page.list)
     * @param category
     * @param action
     * @param [label]
     * @param [labelValue]
     * @param [slot]
     * @param [title]
     * @returns {boolean}
     */
    _record: function(category, action, label, labelValue, slot = '') {
      if(category === 'Widget Track' && siteObj.config.useGa4EnhancedEcom) {
        let eventName = action.toLowerCase() === 'clicked' ? 'select_promotion' : 'view_promotion';
        let eventPage = window.location.pathname;

        /*
        labelValue is the widget ID
        */

        const widgetType = this._widgetDetails(labelValue);

        this._gtmEventTrack({
          'event': 'elysiumEvent',
          'eventData': {
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            eventLabelValue: labelValue,
            eventPage: eventPage,
            slot: slot,
            widgetType: widgetType
          }
        });

        return this._gtmEventTrack({
          'event': 'ecom_event',
          'event_name': eventName,
          'ecommerce' : {
            'promotion_name': label,
            'promotion_id' : labelValue,
            'creative_slot': slot,
            'promotion_page': eventPage,
            'creative_name': widgetType
          }
        });
      }

      return this._gtmEventTrack({
        'event': 'elysiumEvent',
        'eventData': {
          eventCategory: category,
          eventAction: action,
          eventLabel: label,
          eventLabelValue: labelValue
        }
      });
    },

    _widgetDetails: function(widgetId) {
      const widget = document.querySelector(`div[data-widget-id="${widgetId}"]`);
      if ((widget !== null && widget !== 'undefined') && typeof widget === 'object') {
        return widget.dataset['component'];
      }
    },

    /**
     * Default tracking
     * @param name
     * @param action
     * @param value
     * @returns {boolean}
     */
    _defaultTrack: function(name, action, value) {
      var eventObj = {};
      if (name !== null && name !== '') {
        eventObj[name] = (typeof value === 'undefined') ? null : value;
        eventObj.type = action;
        return this._gtmEventTrack(eventObj);
      }
      return false;
    },

    /**
     * Function for recording widgets
     * @param target {string}
     * @returns {boolean}
     */
    _widgetTrack: function(target) {

      var element = target;
      var widgetHref = app.element.getAttribute('href', element);
      var widgetType = app.element.getAttribute('data-block-type', element);
      var widgetDescription = app.element.getAttribute('data-block-name', element);

      var possibleWidget = app.element.closest('.trackwidget', element);

      if (!widgetType) {

        if (possibleWidget) {
          widgetType = possibleWidget.getAttribute('data-block-type');
        } else {
          widgetType = '';
        }

      } else {

        widgetType = ' ' + widgetType;

      }

      if (!widgetDescription) {

        if (possibleWidget) {
          widgetDescription = possibleWidget.getAttribute('data-block-name');
        }

      }

      widgetDescription !== null && widgetDescription !== '' ? widgetDescription.replace(/[^a-zA-Z0-9. ]/g, '') : widgetDescription = 'No widget description';

      return this._gtmEventTrack({
        'event': 'elysiumEvent',
        'eventData': {
          eventCategory: 'Widget Track' + widgetType,
          eventAction: widgetHref,
          eventLabel: widgetDescription,
          eventPage: window.location.pathname
        }
      });
    },

    _pyopTrack: function(target) {
      var pyopHref = app.element.getAttribute('href', target);
      var bundleContents = pyopHref.split('=')[1];

      return this._gtmEventTrack({
        'event': 'elysiumEvent',
        'eventData': {
          eventCategory: 'PYOP',
          eventAction: bundleContents,
          eventLabel: 'click',
          eventPage: window.location.pathname
        }
      });
    },

    /**
     * Enhanced Ecommerce Tracking - Add to Cart / Remove from Cart
     * @param category
     * @param action
     * @param label
     * @param labelValue
     * @param currencyCode
     * @returns {boolean}
     * @private
     */
    _ecommerce: function(category, action, label, labelValue, currencyCode) {
      var ecommerceObject = {
        'event': category,
        'ecommerce': {}
      };

      switch (action) {
        case 'remove':
        case 'add':
          //rename object properties to passed variable names
          ecommerceObject.ecommerce[action] = {};
          if (label) {
            ecommerceObject.ecommerce[action][label] = labelValue;
          }
          break;
        default:
          break;
      }

      if (currencyCode) {
        ecommerceObject.ecommerce.currencyCode = currencyCode;
      }
      return this._gtmEventTrack(ecommerceObject);
    },

    /**
     * Form Timer Tracking
     * @param timingCategory
     * @param timingVariable
     * @param timingLabel
     * @param timingValue
     * @returns {boolean}
     */
    _fieldtiming: function(timingCategory, timingVariable, timingLabel, timingValue) {
      var fieldTimingObject = {
        event: 'formTimer',
        formtiming: {
          timingCategory: timingCategory,
          timingVariable: timingVariable,
          timingLabel: timingLabel,
          timingValue: timingValue
        }
      };
      return this._gtmEventTrack(fieldTimingObject);
    },

    /**
     * Extract price from DOM or siteObj
     * TODO - needs better implementation
     * @param pageType {string}
     * @param context {element}
     * @returns {string}
     */
    getPrice: function(pageType, context) {
      try {

        // take context
        var price;

        if (siteObj.siteCode === 'myprotein' || siteObj.siteCode === 'massint' || siteObj.siteCode === 'pmint') {


          if (pageType === 'product') {

            price = siteObj.productPrice.split(';')[1] || siteObj.productPrice;

            return [price, siteObj.currencyType];

          } else if (pageType === 'list') {

            try {
              price = app.select('.price-box .price').elements[0][0].innerText.split(/\n/)[1].slice(1, -1);
            } catch (e) {
              $console.error('Error getting price');
            }

            if (!price) {
              price = context.parentNode.parentNode.querySelectorAll('.price span')[0].innerText;
            }

            return [price, siteObj.currencyType];
          }

        } else {

          if (pageType === 'product') {

            price = siteObj.productPrice.split(';')[1] || siteObj.productPrice;

            return [price, siteObj.currencyType];

          } else if (pageType === 'list') {


            return [app.select('.price span:first-child').elements[0][0].innerText.slice(1, -1), siteObj.currencyType];
          }
        }
      } catch (e) {

        $console.error('Error finding price: ' + e);
      }
    },

    /**
     * Construct string of arguments
     * @returns {string}
     */
    buildArgs: function() {
      var output = [];

      for (var i = 0; i < arguments.length; i++) {

        if (Array.isArray(arguments[i])) {

          for (var j = 0; j < arguments[i].length; j++) {

            output.push(arguments[i][j]);
          }
        } else {

          output.push(arguments[i]);
        }
      }

      return output;
    },

    /**
     * Extract product sku from an href or default to siteObj if no href provided
     * @param string {string}
     * @param pageType {string}
     * @returns {string}
     */
    getSku: function(string, pageType) {

      var page = pageType;

      if (!pageType) {
        page = siteObj.page || siteObj.type;
      }

      if (!arguments.length || page === 'product') {
        return siteObj.productID;
      }

      return string.split('/')[string.split('/').length - 1].split('.')[0];
    },

    /**
     * Loop up the DOM around a fancySlider banner until we get the clicked href
     * @param element {HTMLElement}
     */
    bannerLink: function(element) {

      if (!element) {
        return null;
      }

      var focus = element;
      var href;

      while (!href) {

        if (app.element.hasClass('carouselMain__imageContainer', element)) {

          href = focus.parentNode.href;

        } else if (app.element.hasClass('carousel-main', element)) {

          href = 'Unable to find href';

        } else {

          focus = focus.parentNode;
        }
      }

      return href;
    },

  };

  _tracking.init();

  return _tracking;
});
